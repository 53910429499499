<template>
  <div>
    <div class="list">
      <div
          class="item"
          :class="item.showBgColor ? 'bg-green' : ''"
          v-for="(item, index) in list"
          :key="index"
      >
        <div class="content">
          <div class="image-box">
            <div class="content-tag-left position-box">{{ manualMachineType[item.manualMachineStatus] }}</div>
            <div class="content-tag-right position-box">{{ item.confirmSize || 0 }}</div>
            <div class="content-tag-bottom-left position-box">{{ item.top1Distance }}</div>
            <div class="content-tag-bottom-right position-box">{{ item.id }}</div>
            <div
                v-for="(el, index) in [item.frontImage, item.backImage]"
                class="image"
                :key="index"
                :style="{'background-image': `url(${el})`}"
                @click="previewImg(index, item)"
            />
          </div>
        </div>
        <div class="footer">
          <a-checkbox class="checked" v-model:checked="item.checkStatus"></a-checkbox>
          <a
              class="ml-10 text-text" :class="item.followGzhStatus ? 'color-white' : ''"
              @click="handleGoToVersionDetail(item)"
          >查看对版详情</a>
          <a-popconfirm
              title="确定要放入池子吗？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="handlePushPoor(item.id)"
          >
            <a-button
                class="ml-10"
                size="small"
            >放入池子</a-button>
          </a-popconfirm>
          <a-button
              class="ml-10"
              size="small"
              @click="handleShowSelectedTagTip(item)"
          >审核</a-button>
          <a-popconfirm
              title="确定要删除此项么？"
              ok-text="确认"
              cancel-text="取消"
              @confirm="handleRemove(item.id)"
          >
            <a-button
                class="ml-10"
                size="small"
                type="danger"
            >剔除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </div>
    <a-empty :description="false" class="null" v-if="list.length <= 0" />
    <SelectedTagTipPopup
      ref="selectedTagTipPopupEl"
      @success="handleSelectedTipSuccess"
    />
  </div>
</template>

<script>
import {manualMachineTypeData, platformTypeData} from "@/views/cmsPage/versionManage/duibanPool/_data";
import SelectedTagTipPopup from "@/views/cmsPage/versionManage/duibanPool/_components/SelectedTagTipPopup"

export default {
  props: {
    list: {
      default: [],
    },
  },
  components: {SelectedTagTipPopup},
  data() {
    return {
      platformType: platformTypeData,
      manualMachineType: manualMachineTypeData,
    }
  },
  methods: {
    /** 展示搜索原因 */
    handleShowSelectedTagTip(item) {
      this.$refs.selectedTagTipPopupEl.show(item)
    },
    /** 选择tag成功 */
    handleSelectedTipSuccess() {
      this.$emit("editSuccess")
    },
    /** 去往对版详情  */
    handleGoToVersionDetail(item) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqmanage/duibanrecord?recordId=${item.id}`;
      window.open(url)
    },
    /** 加入池子 */
    handlePushPoor(id) {
      this.axios("/dq_admin/duibanPushQuestion/pushToDuibanQuestions", {
        params: {
          duibanIds: id
        },
      }).then(() => {
        this.$emit("editSuccess", id);
        this.$message.success("加入成功");
      });
    },
    /** 删除 */
    handleRemove(id) {
      this.axios("/dq_admin/duibanPushQuestion/removeToDuibanQuestions", {
        params: {
          duibanIds: id
        },
      }).then(() => {
        this.$emit("deleteSuccess");
        this.$message.success("删除成功");
      });
    },
    // 预览
    previewImg(index, item) {
      this.$previewImg({
        current: index,
        list: [{img_url: item.frontImage}, {img_url: item.backImage}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.null {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      margin-top: 10px;
      .image-box {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .content-tag-right {
          right: 0;
          top: 0;
        }
        .content-tag-bottom-right {
          right: 0;
          bottom: 0;
        }
        .content-tag-bottom-left {
          left: 0;
          bottom: 0;
        }
        .content-tag-left {
          left: 0;
          top: 0;
        }
        .position-box {
          position: absolute;
          padding: 3px 10px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, .6);
          color: #FFFFFF;
        }
        .image {
          width: 180px;
          height: 180px;
          background-position: center center;
          background-repeat: no-repeat;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          background-size: cover;
        }
      }
    }
    .footer {
      margin-top: 10px;
    }
  }
}
.ml-10 {
  margin-left: 10px !important;
}
.text-text {
  color: #001529 !important;
  text-decoration: underline;
  cursor: pointer;
}
.bg-green {
  background-color: green !important;
}
.color-green {
  color: green !important;
}
.color-white {
  color: #fff0f6 !important;
}
</style>