<template>
  <div class="content">
    <div class="top">
      <!-- 选择大币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          :dropdownMenuStyle="{'max-height': '350px'}"
          allowClear
          showSearch
          size="small"
          @search='searchData'
          style="margin: 5px; width: 180px;"
          v-model="params.coinKindId"
          placeholder="请选择大币种"
          @change="handleChangeCoin($event)"
      >
        <a-select-option
            v-for="items of bigCoinList"
            :key="items.id"
            :value="items.id"
        >{{ items.coinKindName }}</a-select-option>
      </a-select>
      <!-- 选择小币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          @change="coinItemChange"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.coinKindItemId"
          placeholder="请选择小币种"
      >
        <a-select-option
            v-for="item of smallCoinList"
            :key="item.coinKindItemId"
            :value="item.coinKindItemId"
        >{{ item.coinKindItemName }}</a-select-option>
      </a-select>
      <!-- 选择版别 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          @select="search"
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 260px"
          v-model="params.coinVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="请选择版别"
      >
        <a-select-option
            v-for="item of versionList"
            :key="item.coinVersionId"
            :value="item.coinVersionId"
        >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}</a-select-option>
      </a-select>

      <!--   模型   -->
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          showSearch
          size="small"
          class="header-item"
          v-model="params.modelVersion"
          placeholder="模型版本"
          style="margin: 5px; width: 180px;"
          :dropdownStyle="{'max-height': '800px'}"
      >
        <a-select-option
            v-for="items of modelVersionList"
            :key="items.value"
            :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>
      
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          size="small"
          class="header-item"
          v-model="params.markTag"
          placeholder="标签筛选"
          style="margin: 5px; width: 100px"
      >
        <a-select-option
            v-for="items of markTagList"
            :key="items.value"
            :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          size="small"
          class="header-item"
          style="margin: 5px; width: 150px"
          v-model="params.manualMachineStatus"
          placeholder="人工Or机器"
      >
        <a-select-option
            v-for="items of manualMachineStatusList"
            :key="items.value"
            :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>

      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          size="small"
          class="header-item"
          style="margin: 5px; width: 150px"
          v-model="params.platformType"
          placeholder="对版来源类型"
      >
        <a-select-option
            v-for="items of platformTypeList"
            :key="items.value"
            :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>
      
      <a-button
          size="small"
          type="primary"
          @click="search"
      >搜索</a-button>
      <div class="select-all-text">
        <a-checkbox
            v-model="checkAll"
            :indeterminate="indeterminate"
            @change="onCheckAllChange"
        >
          全选
        </a-checkbox>
      </div>
      <div class="change-box">
        <a-popconfirm
            title="确定吗？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleAllConfirm('confirm')"
        >
          <a-button
              size="small"
              style="margin-right: 5px"
              type="primary"
          >批量入池</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="确定吗？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleBatchDeletePool('confirm')"
        >
          <a-button
              size="small"
              style="margin-right: 5px"
              type="danger"
          >批量剔除</a-button>
        </a-popconfirm>
      </div>
    </div>
    <div class="table-content">
      <PoolTable
          :list="data"
          @deleteSuccess="editSuccess"
          @editSuccess="editSuccess"
      ></PoolTable>
      <div class="pagination">
        <a-pagination
            :pageSize.sync="pagination.pageSize"
            :current="pagination.current"
            :total="pagination.total"
            simple
            @change="changePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PoolTable from "@/views/cmsPage/versionManage/duibanPool/_components/PoolTable";
import {
  manualMachineStatusList,
  markTagListPool,
  platformTypeList
} from "@/views/cmsPage/versionManage/duibanrecord/data";
import untils from "@/untils";
import {modelVersionList} from "@/views/cmsPage/versionManage/_data"

export default {
  components: {
    PoolTable
  },
  data() {
    return {
      modelVersionList: modelVersionList,
      untils,
      markTagList:markTagListPool,
      platformTypeList: platformTypeList,
      manualMachineStatusList: manualMachineStatusList,
      checkAll: false,
      indeterminate: false,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
      },
      params: {
        // ?coinKindId=2&coinKindItemId=xx&coinVersionId=xx&markTag=xx&pageNum=xx&pageSize=xx
        pageSize: 30,
        coinKindId: undefined,
        coinKindItemId: undefined,
        coinVersionId: undefined,
        platformType: undefined,
        manualMachineStatus: undefined,
        pageType: undefined,
        startId: 0,
        modelVersion: undefined
      },
      bigCoinList: [],
      smallCoinList: [],
      versionList: [],
      bigCoinText: '',
      cacheData: {}
    };
  },
  mounted() {

  },
  watch: {
    data: {
      deep: true,
      handler() {
        let listStatus = []
        const listLength = this.data.length
        this.data.forEach(item => {
          if (item.checkStatus) {
            listStatus.push(item.id)
          }
        })
        if (listStatus.length === listLength) {
          this.checkAll = true
          this.indeterminate = false
        }
        if (listStatus.length > 0 && listStatus.length < listLength) {
          this.indeterminate = true
        }
        if (listStatus.length === 0) {
          this.checkAll = false
          this.indeterminate = false
        }
      }
    }
  },
  methods: {
    /** 批量剔除 */
    async handleBatchDeletePool() {
      let batchIds = []
      this.data.forEach(el => {
        if (el.checkStatus) {
          batchIds.push(el.id)
        }
      })
      if (batchIds && batchIds.length) {
        this.$loading.show()
        let ids = batchIds.join(",");
        const res = await this.axios("/dq_admin/duibanPushQuestion/removeToDuibanQuestions", {
          params: { duibanIds: ids },
        });
        this.$loading.hide()
        if (res.status !== '200') return;
        this.$message.success(res.message || "操作成功");
        await this.getList();
      } else {
        this.$message.warning('请先选择要处理的数据')
      }
    },
    /** 批量增加已处理 */
    async handleAllConfirm() {
      let batchIds = []
      this.data.forEach(el => {
        if (el.checkStatus) {
          batchIds.push(el.id)
        }
      })
      if (batchIds && batchIds.length) {
        this.$loading.show()
        let ids = batchIds.join(",");
        const res = await this.axios("/dq_admin/duibanPushQuestion/pushToDuibanQuestions", {
          params: { duibanIds: ids },
        });
        this.$loading.hide()
        if (res.status !== '200') return;
        this.$message.success(res.message || "操作成功");
        this.cacheData[this.params.coinKindId] = {}
        this.cacheData[this.params.coinKindId]['minId'] = Math.min(...batchIds)
        this.cacheData[this.params.coinKindId]['maxId'] = Math.max(...batchIds)
        localStorage.setItem('poolCacheData', JSON.stringify(this.cacheData))
        await this.getList();
      } else {
        this.$message.warning('请先选择要处理的数据')
      }
    },
    editSuccess(id) {
      if (id) {
        if (this.cacheData[this.params.coinKindId]) {
          const data = this.cacheData[this.params.coinKindId]
          const minId =  data.minId
          const maxId =  data.maxId
          this.cacheData[this.params.coinKindId]['minId'] = minId
          this.cacheData[this.params.coinKindId]['maxId'] = maxId
          if (id >= maxId) {
            this.cacheData[this.params.coinKindId]['maxId'] = id
          }
          if (id <= minId) {
            this.cacheData[this.params.coinKindId]['minId'] = id
          }
        } else {
          this.cacheData[this.params.coinKindId] = {}
          this.cacheData[this.params.coinKindId]['minId'] = id
          this.cacheData[this.params.coinKindId]['maxId'] = id
        }
        localStorage.setItem('poolCacheData', JSON.stringify(this.cacheData))
      }
      this.getList()
    },
    /** 获取大币种列表 */
    searchData(inner){
      if (!inner) return
      this.axios('/dq_admin/kind/list',{params:{
          coinName: inner
      }}).then(res=>{
        if(res.status == '200'){
          const {records} = res.data
          this.bigCoinList = records
        }
      })
    },
    /** 选择大币种 */
    handleChangeCoin(e) {
      this.bigCoinText = this.bigCoinList.find(el => el.id === e).coinKindName
      delete this.params.coinKindItemId;
      delete this.params.coinVersionId
      if (!e) {
        this.bigCoinList = []
        this.smallCoinList = [];
        this.versionList = [];
        return;
      }
      this.getSmallCoinList();
    },
    /** 查询小币种 */
    async getSmallCoinList() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinItems", {
        params: {
          coinId: this.params.coinKindId,
        },
      });
      if (res.status != 200) return;
      this.smallCoinList = res.data;
    },
    /** 选择小币种 */
    coinItemChange(val) {
      delete this.params.coinVersionId;
      if (!val) {
        this.versionList = [];
        return;
      }
      this.getVersionType();
    },
    /** 获取版别 */
    async getVersionType() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinVersions", {
        params: {
          coinId: this.params.coinKindId,
          coinItemId: this.params.coinKindItemId,
        },
      });
      if (res.status != 200) return;
      this.versionList = res.data;
    },
    /** 获取列表数据 */
    async getList() {
      this.cacheData = JSON.parse(localStorage.getItem('poolCacheData')) || {}
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanPushQuestion/selectToDuibanQuestions", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      let list = res.data.records;
      if (Object.keys(this.cacheData).length && Object.keys(this.cacheData).includes(this.params.coinKindId + '')) {
        const data = this.cacheData[this.params.coinKindId]
        const minId =  data.minId
        const maxId =  data.maxId
        list.forEach(el => {
          if (el.id > minId && el.id < maxId) {
            el.showBgColor = true
          }
        })
      }
      list.forEach(item => {
        item.checkStatus = false
      })
      this.data = list;
      this.pagination.total = res.data.total;
    },
    /** 翻页 */
    changePage(page) {
      const currentPage = this.pagination.current
      const changeDown = currentPage + 1 === page
      const changeUp = currentPage - 1 === page
      if (currentPage + 1 === page || currentPage - 1 === page ) {
        if (changeDown) {
          this.params.startId = this.data[this.data.length - 1].id
          this.params.pageType = 'down'
        }
        if (changeUp) {
          this.params.startId = this.data[0].id
          this.params.pageType = 'up'
        }
        this.pagination.current = page;
        this.getList();
      } else {
        this.$message.warn('请按照顺序切换页码')
      }
    },
    /** 搜索 */
    search() {
      this.params.startId = 0;
      this.pagination.current = 1;
      this.getList();
    },
    /** 修改全选 */
    onCheckAllChange(e) {
      if (e.target.checked) {
        this.data.forEach(item => {
          item.checkStatus = true
        })
      } else {
        this.data.forEach(item => {
          item.checkStatus = false
        })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
.pagination::v-deep .ant-pagination-simple-pager > input {
  pointer-events: none;
}
</style>
