var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "item",
              class: item.showBgColor ? "bg-green" : ""
            },
            [
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  { staticClass: "image-box" },
                  [
                    _c(
                      "div",
                      { staticClass: "content-tag-left position-box" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.manualMachineType[item.manualMachineStatus]
                          )
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "content-tag-right position-box" },
                      [_vm._v(_vm._s(item.confirmSize || 0))]
                    ),
                    _c(
                      "div",
                      { staticClass: "content-tag-bottom-left position-box" },
                      [_vm._v(_vm._s(item.top1Distance))]
                    ),
                    _c(
                      "div",
                      { staticClass: "content-tag-bottom-right position-box" },
                      [_vm._v(_vm._s(item.id))]
                    ),
                    _vm._l([item.frontImage, item.backImage], function(
                      el,
                      index
                    ) {
                      return _c("div", {
                        key: index,
                        staticClass: "image",
                        style: { "background-image": "url(" + el + ")" },
                        on: {
                          click: function($event) {
                            return _vm.previewImg(index, item)
                          }
                        }
                      })
                    })
                  ],
                  2
                )
              ]),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c("a-checkbox", {
                    staticClass: "checked",
                    model: {
                      value: item.checkStatus,
                      callback: function($$v) {
                        _vm.$set(item, "checkStatus", $$v)
                      },
                      expression: "item.checkStatus"
                    }
                  }),
                  _c(
                    "a",
                    {
                      staticClass: "ml-10 text-text",
                      class: item.followGzhStatus ? "color-white" : "",
                      on: {
                        click: function($event) {
                          return _vm.handleGoToVersionDetail(item)
                        }
                      }
                    },
                    [_vm._v("查看对版详情")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定要放入池子吗？",
                        "ok-text": "确认",
                        "cancel-text": "取消"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handlePushPoor(item.id)
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        { staticClass: "ml-10", attrs: { size: "small" } },
                        [_vm._v("放入池子")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-10",
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowSelectedTagTip(item)
                        }
                      }
                    },
                    [_vm._v("审核")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定要删除此项么？",
                        "ok-text": "确认",
                        "cancel-text": "取消"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleRemove(item.id)
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-10",
                          attrs: { size: "small", type: "danger" }
                        },
                        [_vm._v("剔除")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        }),
        0
      ),
      _vm.list.length <= 0
        ? _c("a-empty", { staticClass: "null", attrs: { description: false } })
        : _vm._e(),
      _c("SelectedTagTipPopup", {
        ref: "selectedTagTipPopupEl",
        on: { success: _vm.handleSelectedTipSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }