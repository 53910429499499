<template>
  <a-modal
      centered
      :width='600'
      title="标记"
      v-model="showModal"
      @ok='handleConfirm'
      @cancel='handleCancel'
  >
    <div class="content-modal">
      <template v-for="(tag, index) in errMsgList">
        <div :key="tag.id" v-if="!inputVisible && tag.id === 'auto' || tag.id !== 'auto'">
          <a-tag
              class="cursor-pointer"
              :color="tag.selected ? 'green' : tag.id === 'auto' ? 'blue' : ''"
              :closable="tag.close"
              @click="handleChangeTag(tag.id)"
          >
            <a-icon color="blue" v-if="tag.id === 'auto'" type="edit"></a-icon>{{ tag.value }}</a-tag>
        </div>
      </template>
      <a-input
          v-if="inputVisible"
          ref="input"
          type="text"
          :style="{ width: '278px' }"
          :value="inputValue"
          @change="handleInputChange"
          @keyup.enter="handleInputConfirm"
      />
    </div>
  </a-modal>
</template>
<script>
import {errMsgList} from "@/views/cmsPage/versionManage/_data"

export default {
  computed: {
  },
  data() {
    return {
      showModal: false,
      inputVisible: false,
      inputValue: '',
      errMsgList: [],
      duiBanId: ''
    }
  },
  methods: {
    show(data) {
      this.duiBanId = data.id
      errMsgList.forEach(el => {
        el.selected = false
      })
      this.errMsgList = errMsgList
      this.showModal = true
    },
    /** 向列表增加数据 */
    handleInputConfirm() {
      const tempTag = {
        id: this.errMsgList.length + 1,
        value: this.inputValue,
        selected: true,
        close: true
      }
      this.errMsgList = [...this.errMsgList, tempTag]
      this.inputVisible = false
    },
    /** 改变input值 */
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    /** 改变选择tag */
    handleChangeTag(id) {
      if (id !== 'auto') {
        this.errMsgList.forEach(el => {
          el.selected = el.id === id;
        })
        this.inputVisible = false
      } else {
        this.errMsgList.forEach(el => {
          el.selected = false
        })
        this.inputVisible = true
      }
    },
    /** 确定 */
    async handleConfirm() {
      const found = this.errMsgList.find(el => el.selected)
      if (!found && !found.id) return this.$message.warn('请选择原因，或自定义原因')
     const res = await this.axios("/dq_admin/duibanUserRecord/reviewToSquare", {
       params: {
         id: this.duiBanId,
         markSectionType: 'private',
         errorTips: found.value,
       }})
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.showModal = false
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .ant-tag {
  padding: 6px !important;
  font-size: 16px !important;
  margin-bottom: 6px !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>