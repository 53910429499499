var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { centered: "", width: 600, title: "标记" },
      on: { ok: _vm.handleConfirm, cancel: _vm.handleCancel },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        { staticClass: "content-modal" },
        [
          _vm._l(_vm.errMsgList, function(tag, index) {
            return [
              (!_vm.inputVisible && tag.id === "auto") || tag.id !== "auto"
                ? _c(
                    "div",
                    { key: tag.id },
                    [
                      _c(
                        "a-tag",
                        {
                          staticClass: "cursor-pointer",
                          attrs: {
                            color: tag.selected
                              ? "green"
                              : tag.id === "auto"
                              ? "blue"
                              : "",
                            closable: tag.close
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleChangeTag(tag.id)
                            }
                          }
                        },
                        [
                          tag.id === "auto"
                            ? _c("a-icon", {
                                attrs: { color: "blue", type: "edit" }
                              })
                            : _vm._e(),
                          _vm._v(_vm._s(tag.value))
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          }),
          _vm.inputVisible
            ? _c("a-input", {
                ref: "input",
                style: { width: "278px" },
                attrs: { type: "text", value: _vm.inputValue },
                on: {
                  change: _vm.handleInputChange,
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleInputConfirm($event)
                  }
                }
              })
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }